import React from "react";
import Head from "next/head";

import { ShipdaCurrentLanguage } from "@sellernote/_shared/src/i18n/i18nForShipda";

import { DEFAULT_SEO } from "./constants";

/**
 * 쉽다/브링굿즈에서만 사용하므로 containers에 둚
 */
export default function Seo({
  title,
  path,
  imageUrl,
  description,
  customTags,
}: {
  title?: string;
  path?: string;
  imageUrl?: string;
  description?: string;
  customTags?: { name: string; content: string }[];
}) {
  title = title
    ? title
    : DEFAULT_SEO[ShipdaCurrentLanguage.currentLanguage]["title"];

  path = path ? path : "/";

  description = description
    ? description
    : DEFAULT_SEO[ShipdaCurrentLanguage.currentLanguage]["description"];

  if (!imageUrl) {
    imageUrl = "/assets/images/seo/og-image-kr.webp";
  }

  const siteName =
    DEFAULT_SEO[ShipdaCurrentLanguage.currentLanguage]["siteName"];

  const host = "https://www.ship-da.com";

  const url = `${host}${path}`;

  return (
    <>
      <Head>
        <link rel="icon" href="/assets/favicon.ico" />

        <meta name="theme-color" content="#000000" />
        <meta
          name="viewport"
          content="initial-scale=1.0,user-scalable=no,width=device-width"
        />

        <title>{title}</title>
        <meta name="name" content={siteName} />
        <meta name="url" content={url} />
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="600" />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={siteName} />

        <meta property="twitter:title" content={title} />
        {/* <meta property="twitter:site" content="@ID" /> */}
        <meta property="twitter:card" content={description} />
        <meta property="twitter:description" content={description} />

        <meta name="locale" content={ShipdaCurrentLanguage.currentLanguage} />
        <meta name="robots" content="index, follow" />
        <meta
          name="author"
          content={DEFAULT_SEO[ShipdaCurrentLanguage.currentLanguage]["author"]}
        />
        <meta
          name="publisher"
          content={
            DEFAULT_SEO[ShipdaCurrentLanguage.currentLanguage]["publisher"]
          }
        />
        <meta
          name="subject"
          content={
            DEFAULT_SEO[ShipdaCurrentLanguage.currentLanguage]["subject"]
          }
        />

        <link rel="canonical" href={`${host}${path}`} />

        {/* SEO보다 페이스북인사이트를 위함. 필요없다면 안 넣어도 상관없다 */}
        <meta
          property="fb:app_id"
          content={process.env.NEXT_PUBLIC_FACEKBOOK_PIXEL_ID}
        />

        {customTags &&
          customTags.map((t, i) => (
            <meta key={i} name={t.name} content={t.content} />
          ))}
      </Head>

      {/* 연관채널 */}
      <span itemScope={"" as any} itemType="http://schema.org/Organization" />
      <link itemProp="url" href={host} />
      <a
        itemProp="sameAs"
        href="https://www.youtube.com/channel/UCtP8od_ijpAr6n1siRbXseQ"
      />
      <a
        itemProp="sameAs"
        href="https://www.ship-da.com/blog?utm_source=inblog&utm_medium=referral&utm_campaign=homepage&utm_id=inblog_main&utm_content=inblog_main"
      />
      <a itemProp="sameAs" href="https://www.instagram.com/globalshipda" />
      <a itemProp="sameAs" href="https://www.facebook.com/globalshipda" />
      <a itemProp="sameAs" href=" https://www.vingle.net/globalshipda" />
    </>
  );
}
