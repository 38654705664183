import SvgIconWrapper from "./SvgIconWrapper";

export default function QuoteIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
      >
        <path
          d="M4.39895 14.0383H2.8125C2.03709 14.0383 1.40625 13.4075 1.40625 12.632V2.8125C1.40625 2.03709 2.03709 1.40625 2.8125 1.40625H10.457C11.2324 1.40625 11.8633 2.03709 11.8633 2.8125V5.14681C11.8633 5.53514 12.1781 5.84993 12.5664 5.84993C12.9547 5.84993 13.2695 5.53514 13.2695 5.14681V2.8125C13.2695 1.26169 12.0078 0 10.457 0H2.8125C1.26169 0 0 1.26169 0 2.8125V12.632C0 14.1829 1.26169 15.4445 2.8125 15.4445H4.39895C4.78729 15.4445 5.10207 15.1298 5.10207 14.7414C5.10207 14.3531 4.78729 14.0383 4.39895 14.0383Z"
          fill="currentColor"
          fillOpacity="0.6"
        />
        <path
          d="M7.5 7.01562C7.5 6.62729 7.18521 6.3125 6.79687 6.3125H3.20312C2.81479 6.3125 2.5 6.62729 2.5 7.01562C2.5 7.40396 2.81479 7.71875 3.20312 7.71875H6.79687C7.18521 7.71875 7.5 7.40396 7.5 7.01562Z"
          fill="currentColor"
          fillOpacity="0.6"
        />
        <path
          d="M3.20313 9.125C2.81479 9.125 2.5 9.43979 2.5 9.82812C2.5 10.2165 2.81479 10.5312 3.20313 10.5312H4.60156C4.9899 10.5312 5.30468 10.2165 5.30468 9.82812C5.30468 9.43979 4.9899 9.125 4.60156 9.125H3.20313Z"
          fill="currentColor"
          fillOpacity="0.6"
        />
        <path
          d="M9.44531 3.5H3.20312C2.81479 3.5 2.5 3.81479 2.5 4.20312C2.5 4.59146 2.81479 4.90625 3.20312 4.90625H9.44531C9.83365 4.90625 10.1484 4.59146 10.1484 4.20312C10.1484 3.81479 9.83365 3.5 9.44531 3.5Z"
          fill="currentColor"
          fillOpacity="0.6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5 16.7C13.8196 16.7 15.7 14.8196 15.7 12.5C15.7 10.1804 13.8196 8.3 11.5 8.3C9.1804 8.3 7.3 10.1804 7.3 12.5C7.3 14.8196 9.1804 16.7 11.5 16.7ZM11.5 18C14.5376 18 17 15.5376 17 12.5C17 9.46243 14.5376 7 11.5 7C8.46243 7 6 9.46243 6 12.5C6 15.5376 8.46243 18 11.5 18Z"
          fill="currentColor"
          fillOpacity="0.6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.76319 10.7745C9.65663 10.4799 9.33138 10.3274 9.03674 10.434C8.74209 10.5406 8.58963 10.8658 8.69619 11.1605L8.73726 11.274C8.48662 11.3328 8.3 11.5578 8.3 11.8264C8.3 12.1397 8.554 12.3937 8.86732 12.3937H9.14222L10.0498 14.9031C10.135 15.1386 10.3646 15.2904 10.6146 15.2766C10.8647 15.2628 11.0761 15.0866 11.1348 14.8431L11.4857 13.3878L11.8366 14.8431C11.8953 15.0866 12.1068 15.2628 12.3568 15.2766C12.6069 15.2904 12.8365 15.1386 12.9216 14.9031L13.8292 12.3937H14.1041C14.4175 12.3937 14.6715 12.1397 14.6715 11.8264C14.6715 11.5578 14.4848 11.3328 14.2342 11.274L14.2753 11.1605C14.3818 10.8658 14.2294 10.5406 13.9347 10.434C13.6401 10.3274 13.3148 10.4799 13.2083 10.7745L13.033 11.259H12.1396L12.0372 10.8345C11.9758 10.5797 11.7478 10.4002 11.4857 10.4002C11.2236 10.4002 10.9957 10.5797 10.9342 10.8345L10.8319 11.259H9.93842L9.76319 10.7745ZM10.3488 12.3937L10.4745 12.7412L10.5583 12.3937H10.3488ZM12.4132 12.3937L12.497 12.7412L12.6227 12.3937H12.4132Z"
          fill="currentColor"
          fillOpacity="0.6"
        />
      </svg>
    </SvgIconWrapper>
  );
}
